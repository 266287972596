@import "~antd/dist/antd.less";

@media only screen and (min-width: 320px) {
  .col-1 > h2 {
    color: white;
    font-weight: normal;
    font-size: 22px;
  }
  .descriptions-col-2 {
    padding: 20px;
  }

  .col-2 > h4 {
    color: #002e5b;
    font-weight: bold;
    font-size: 18px;
  }
  .description {
    padding: 22px;
    padding-top: 5px;
    margin-bottom: -30px;
    font-weight: lighter;
  }

  .profile-image {
    max-width: 220px;
    width: auto;
    height: auto;
    border-radius: 300px;
  }
  .col-1 {
    padding: 10px;
    background-color: #002e5b;
    color: white;
  }
  .col-2 {
    padding: 22px;
  }
  .col-2 > h1 {
    font-weight: lighter;
    font-size: 28px;
    color: #002e5b;
  }
  .col-2 > h2 {
    font-weight: bold;
    font-size: 25px;
    color: #002e5b;
  }
  .descriptions-col-2 > h2 {
    color: white;
    font-weight: normal;
    font-size: large;
  }
  .descriptions-col-2 > p {
    color: white;
    font-weight: lighter;
  }
}

@media only screen and (min-width: 768px) {
  .col-1 > h2 {
    color: white;
    font-weight: normal;
    font-size: 22px;
  }
  .descriptions-col-2 {
    padding: 30px;
  }

  .col-2 > h4 {
    color: #002e5b;
    font-weight: bold;
    font-size: 18px;
  }
  .description {
    padding: 25px;
    padding-top: 5px;
    font-weight: lighter;
  }

  .profile-image {
    max-width: 280px;
    width: auto;
    height: auto;
    border-radius: 300px;
  }
  .col-1 {
    padding: 25px;
    background-color: #002e5b;
    color: white;
  }
  .col-2 {
    padding: 30px;
  }
  .col-2 > h1 {
    font-weight: lighter;
    font-size: 50px;
    color: #002e5b;
  }
  .col-2 > h2 {
    font-weight: bold;
    font-size: 25px;
    color: #002e5b;
  }
  .descriptions-col-2 > h2 {
    color: white;
    font-weight: normal;
    font-size: large;
  }
  .descriptions-col-2 > p {
    color: white;
    font-weight: lighter;
  }
}

@media only screen and (min-width: 992px) {
  .col-1 > h2 {
    color: white;
    font-weight: normal;
    font-size: 22px;
  }
  .descriptions-col-2 {
    padding: 30px;
  }

  .col-2 > h4 {
    color: #002e5b;
    font-weight: bold;
    font-size: 18px;
  }
  .description {
    padding: 25px;
    padding-top: 5px;
    font-weight: lighter;
  }

  .profile-image {
    max-width: 280px;
    width: auto;
    height: auto;
    border-radius: 300px;
  }
  .col-1 {
    padding: 25px;
    background-color: #002e5b;
    color: white;
  }
  .col-2 {
    padding: 30px;
  }
  .col-2 > h1 {
    font-weight: lighter;
    font-size: 50px;
    color: #002e5b;
  }
  .col-2 > h2 {
    font-weight: bold;
    font-size: 25px;
    color: #002e5b;
  }
  .descriptions-col-2 > h2 {
    color: white;
    font-weight: normal;
    font-size: large;
  }
  .descriptions-col-2 > p {
    color: white;
    font-weight: lighter;
  }
}

@media only screen and (min-width: 1280px) {
  .col-1 > h2 {
    color: white;
    font-weight: normal;
    font-size: 22px;
  }
  .descriptions-col-2 {
    padding: 30px;
  }

  .col-2 > h4 {
    color: #002e5b;
    font-weight: bold;
    font-size: 18px;
  }
  .description {
    padding: 25px;
    padding-top: 0px;
    font-weight: lighter;
  }

  .profile-image {
    max-width: 280px;
    width: auto;
    height: auto;
    border-radius: 300px;
  }
  .col-1 {
    padding: 25px;
    background-color: #002e5b;
    color: white;
  }
  .col-2 {
    padding: 30px;
    padding-top: 8px;
  }
  .col-2 > h1 {
    font-weight: lighter;
    font-size: 50px;
    color: #002e5b;
  }
  .col-2 > h2 {
    font-weight: bold;
    font-size: 22px;
    color: #002e5b;
  }
  .descriptions-col-2 > h2 {
    color: white;
    font-weight: normal;
    font-size: large;
  }
  .descriptions-col-2 > p {
    color: white;
    font-weight: lighter;
  }
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-size: 12.8px;
  font-family: "Ubuntu", sans-serif;
}

html,
body,
#root {
  height: 100%;
}

.container-profile {
  display: flex;
  width: 100%;
  justify-content: center;
}
h3 {
  font-weight: bold;
}

.divider-col {
  max-height: 0.3px;
  margin-top: 5px;
  margin-bottom: 3px;
  background: #002e5b;

}

.contacts {
  margin-top: -18px;
}
.contacts > ul {
  list-style: none;
  padding-left: 14px;
}

.list-langs > ul {
  list-style: none;
}
.icon {
  width: 26px;
  align-content: center;
  object-fit: cover;
}
.contact-label {
  color: #002e5b;
  margin-left: 10px;
}
.divider {
  max-height: 0.3px;
  margin-top: 8px;
  margin-bottom: 18px;
  background: white;
}
strong {
  font-weight: bold;
}
.container-app {
  height: 100%;
  background: #fff;
  margin: 0;
  padding: 0;
}

.descriptions-skills {
  padding: 5px;
  padding-left: 15px;
  font-weight: lighter;
}
.descriptions-skills > ul {
  margin-top: -18px;
  padding-left: 5px;
}

@primary-color: #0b1a34;@link-color: #1890ff;@font-size-base: 12.6px;@menu-dark-submenu-bg: #002e5b;@layout-header-background: #002e5b;@font-family: 'Ubuntu', sans-serif;@border-radius-base: 4px;